<template>
  <div
    class="group relative cursor-pointer overflow-hidden rounded-lg border-3 border-transparent transition-all duration-300 hover:rounded-2xl hover:border-blue-500 focus:rounded-2xl hover:green:border-green-500 hover:pink:border-pink-500 hover:blue-el:border-blue-500 hover:green-el:border-green-500 hover:pink-el:border-pink-500"
    @click="$refs?.accordion?.onClickSummary(), (isOpen = !isOpen)"
  >
    <div
      class="flex flex-row divide-x divide-solid divide-gray-200 bg-white py-4 px-7 outline-none md:py-7"
    >
      <div
        class="flex h-36 w-20 shrink-0 flex-col justify-center pr-5 md:h-44 md:w-36 lg:w-48"
      >
        <span
          class="relative z-40 block text-center text-xl font-semibold transition-colors md:text-2xl lg:text-5xl"
          :class="{ 'text-white': isOpen }"
          >{{ day }}</span
        >
        <span
          class="relative z-40 hidden text-center text-2xs font-semibold uppercase !tracking-wider transition-colors md:block md:text-md"
          :class="{ 'text-white': isOpen }"
          >{{ monthLong }}</span
        >
        <span
          class="relative z-40 block text-center text-2xs font-semibold uppercase !tracking-wider transition-colors md:hidden md:text-md"
          :class="{ 'text-white': isOpen }"
          >{{ monthShort }}</span
        >
        <!-- Colorfull open background -->
        <div
          class="absolute left-0 top-0 z-0 h-full w-[6.8rem] transition-transform duration-200 blue-el:bg-blue-500 green-el:bg-green-500 pink-el:bg-pink-500 md:w-44 lg:w-56"
          :class="isOpen ? 'translate-x-0' : '-translate-x-full'"
        >
          <IconEyeAndEar
            class="absolute bottom-0 hidden w-full text-white blue-el:block"
          />
          <IconEye
            class="absolute bottom-0 hidden w-full px-6 text-white pink-el:block"
          />
          <IconEar
            class="absolute bottom-4 hidden w-full px-6 text-white green-el:block"
          />
        </div>
      </div>
      <div class="overflow-wrap-anywhere w-full pl-4 lg:pl-16">
        <Accordion
          ref="accordion"
          prevent-click
          :duration-open="200"
          :duration-close="200"
        >
          <template #summary>
            <h3 class="mb-1 text-base leading-[1.18] md:text-lg lg:text-2xl">
              {{ card.title }}
            </h3>
            <div
              class="text-3xs font-semibold leading-tight !tracking-normal opacity-60 sm:pr-6 md:text-2xs"
            >
              <span v-if="card.location" class="font-normal tracking-widest"
                >Ort </span
              >{{ card.location }}
              <div v-if="card.time" class="mt-2 leading-none md:mt-0">
                <span class="font-normal tracking-widest">Zeit </span
                >{{ card.time }}
              </div>
            </div>
          </template>
          <template #content>
            <div class="overflow-hidden transition-all">
              <div
                class="hyphens-auto min-h-[6rem] max-w-2xl pb-1 pt-5 text-xs md:min-h-[10rem] md:text-sm"
                v-html="card.bard_text || card.lead || ''"
              />
            </div>
          </template>
        </Accordion>
        <div
          class="focus-default text-theme -mx-2 mt-3 w-fit px-2 text-2xs !leading-loose group-focus-visible:ring-2 md:text-xs"
        >
          <IconPlus
            class="mr-2 inline-block w-3 text-inherit lg:w-4"
            :class="{ open: isOpen }"
          />
          <transition name="fade" mode="out-in">
            <span v-if="isOpen" class="underline group-open:inline-block"
              >Weniger anzeigen</span
            >
            <span v-else class="inline-block underline">Mehr anzeigen</span>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useDateFormat } from '@vueuse/core'
const props = defineProps({
  card: { type: Object, required: true },
})

const isOpen = ref(false)

let day = useDateFormat(props.card?.event_date, 'DD')
let monthLong = useDateFormat(props.card?.event_date, 'MMMM', {
  locales: 'de',
})
let monthShort = useDateFormat(props.card?.event_date, 'MMM', {
  locales: 'de',
})
</script>

<style lang="scss">
.open {
  .plus {
    @apply hidden;
  }
}
</style>
